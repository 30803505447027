<template>
  <div>
    <validation-observer ref="formDataValidation">
      <b-form>
        <b-card no-body class="invoice-preview-card">

          <b-card-body class="invoice-padding pb-0">

            <h4 class="text-center">Datos del viaje</h4>
            <hr class="invoice-spacing">
            <b-row>
              <!--Plan-->
              <b-col md="3">
                <validation-provider #default="{ errors }" name="Agencia" rules="required">
                  <b-form-group label="Agencia" label-for="issuePurchaseAgency"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select v-model="formIssuePurchase.agency" :options="agenciesPerUsers" label="name"
                      @input="changePlan(formIssuePurchase.agency)" :state="errors.length > 0 ? false : null" trim
                      clearable />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <validation-provider #default="{ errors }" name="Plan" rules="required">
                  <b-form-group label="Plan" label-for="plan" :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select id="plan" v-model="formData.plan" :options="planOptions" label="name"
                      :state="errors.length > 0 ? false : null" trim clearable />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Modality-->
              <b-col md="3">
                <validation-provider #default="{ errors }" name="Modalidad" rules="required">
                  <b-form-group label="Modalidad" label-for="modality" :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select id="modality" v-model="formData.modality" :options="modalitiesOptions"
                      :reduce="val => val.id" label="name" :state="errors.length > 0 ? false : null" trim clearable />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--numberTravellers-->
              <b-col md="3">
                <validation-provider #default="{ errors }" name="Viajeros" rules="required">
                  <b-form-group label="Viajeros" label-for="numberTravellers"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-spinbutton id="numberTravellers" v-model="formData.numberTravellers"
                      :min="numberTravellersData.min" :max="numberTravellersData.max"
                      :state="errors.length > 0 ? false : null" trim />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <!--Destiny-->
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Destino">
                  <b-form-group label="Destino" label-for="destiny" :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select id="destiny" v-model="formData.destiny" :options="countriesOptions" :reduce="val => val.id"
                      label="name" :state="errors.length > 0 ? false : null" trim clearable />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Initial date-->
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Fecha inicial" rules="required">
                  <b-form-group label="Fecha inicial" label-for="initial_date"
                    :class="errors.length > 0 ? 'is-invalid' : null">

                    <b-form-datepicker id="initial_date" v-model="formData.initial_date" class="form-control"
                      :class="errors.length > 0 ? 'is-invalid' : null" :state="errors.length > 0 ? false : null" trim
                      :max="formData.final_date" placeholder="Seleccionar fecha de salida" locale="es" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Final date-->
              <b-col md="4">
                <validation-provider #default="{ errors }" name="Fecha final" rules="required">
                  <b-form-group label="Fecha final" label-for="final_date"
                    :class="errors.length > 0 ? 'is-invalid' : null">

                    <b-form-datepicker id="final_date" v-model="formData.final_date" class="form-control"
                      :class="errors.length > 0 ? 'is-invalid' : null" :state="errors.length > 0 ? false : null" trim
                      :min="formData.initial_date" locale="es" placeholder="Seleccionar fecha de regreso" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="py-1">
              <b-col md="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="calculateTrip">
                  Calcular
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <div v-if="calculateCard.show">
          <b-row>
            <b-col md="4">
              <b-card no-body class="invoice-preview-card">

                <b-card-body class="invoice-padding pb-0">

                  <h4 class="text-center">Datos importantes</h4>
                  <hr class="invoice-spacing">
                  <b-row>
                    <b-col md="6">
                      <!-- Days -->
                      <validation-provider #default="{ errors }" name="Días" rules="required">
                        <b-form-group label="Días" label-for="days" :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="days" v-model="calculateCard.days" type="number"
                            :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                            disabled />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <!-- Travellers -->
                      <validation-provider #default="{ errors }" name="Viajeros" rules="required">
                        <b-form-group label="Viajeros" label-for="travellers"
                          :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="travellers" v-model="calculateCard.travellers"
                            :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                            disabled />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <!-- Plan -->
                      <b-form-group label="Plan" label-for="plan">
                        <b-form-input id="plan" v-model="calculateCard.plan.name" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <!-- Modality -->
                      <b-form-group label="Modalidad" label-for="modality">
                        <b-form-input id="modality" v-model="calculateCard.modality" disabled />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="8">
              <b-card no-body class="invoice-preview-card">

                <b-card-body class="invoice-padding pb-0">

                  <h4 class="text-center">Precios</h4>
                  <hr class="invoice-spacing">

                  <b-row>
                    <b-col md="2">
                      <!-- Age -->
                      <b-form-group label="T.R.M" label-for="trm">
                        <b-form-input id="trm" v-model="calculateCard.trm" type="number" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <!-- DolarValue -->
                      <b-form-group label="Precio en dolares" label-for="dolarValue">
                        <b-form-input id="dolarValue" v-model="calculateCard.dolarValue" type="number" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <!-- PesosValue -->
                      <b-form-group label="Precio en pesos" label-for="pesosValue">
                        <b-form-input id="pesosValue" v-model="calculateCard.pesosValue" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <!-- Discount -->
                      <b-form-group prepend="0" append="100" label="Descuento" label-for="discount">
                        <b-form-input id="discount" v-model="calculateCard.discount" type="number" min="0" max="100" />
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <!-- Discount -->
                      <b-form-group label-for="upgrade">
                        <b-form-checkbox class="mt-2" id="upgrade" v-model="calculateCard.upgrade">
                          Upgrade
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="6">
                      <!-- totalDolarValue -->
                      <b-form-group label="Total a pagar en dolares" label-for="totalDolarValue">
                        <b-form-input id="totalDolarValue" :value="formatPrice(calculateCard.totalDolarValue)" disabled />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <!-- pesosValue -->
                      <b-form-group label="Total a pagar en pesos" label-for="totalPesosValue">
                        <b-form-input id="totalPesosValue" v-model="calculateCard.totalPesosValue" disabled />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-card>
                <b-row>
                  <b-col md="4">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="success"
                      @click="openformIssuePurchaseModal">
                      <span v-if="voucher">Editar Voucher</span>
                      <span v-else>Generar Voucher</span>
                      <!-- <span v-else>Emitir compra</span> -->
                    </b-button>
                  </b-col>
                  <b-col md="4">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary"
                      v-b-modal.formGenerateQuote>
                      <span v-if="id">Editar Cotización</span>
                      <span v-else>Guardar cotización</span>
                    </b-button>
                  </b-col>
                  <b-col md="4">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="outline-primary"
                      @click="resetData">
                      Nueva cotización
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>

    <validation-observer ref="formGenerateQuoteValidation">
      <b-modal id="formGenerateQuote" ref="formGenerateQuote" size="lg" title="Generar cotización" ok-title="Guardar"
        cancel-title="Cancelar" cancel-variant="outline-secondary" centered @show="resetModalSave"
        @hidden="resetModalSave" @ok="handleOkGenerateQuote">
        <form ref="formGenerateQuote" @submit.stop.prevent="handleSubmitGenerateQuote">
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Agencia" rules="required">
                <b-form-group label="Agencia" label-for="agency" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="agency" v-model="formGenerateQuote.agency" :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null" trim clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="promotor" rules="required">
                <b-form-group label="Promotor" label-for="promoter" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="promoter" v-model="formGenerateQuote.promoter"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" trim
                    clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Nombres" rules="required">
                <b-form-group label="Nombres" label-for="firstName" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="firstName" v-model="formGenerateQuote.firstName"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Apellidos" rules="required">
                <b-form-group label="Apellidos" label-for="lastName" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="lastName" v-model="formGenerateQuote.lastName"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Correo" rules="required|email">
                <b-form-group label="Correo" label-for="email" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="email" type="email" v-model="formGenerateQuote.email"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Teléfono" rules="required">
                <b-form-group label="Teléfono" label-for="phone" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="phone" type="number" v-model="formGenerateQuote.phone"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </validation-observer>

    <validation-observer ref="formIssuePurchaseValidation">
      <b-modal id="formIssuePurchase" ref="formIssuePurchase" size="xl" v-model="formIssuePurchase.modal"
        title="Emitir váucher" ok-title="Guardar" cancel-title="Cancelar" cancel-variant="outline-secondary" centered
        @show="resetIssuePurchaseModalSave" @hidden="resetIssuePurchaseModalSave" @ok="handleOkGenerateIssuePurchase">
        <form ref="formIssuePurchase" @submit.stop.prevent="handleOkGenerateIssuePurchase">
          <b-row>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Agencia" rules="required">
                <b-form-group label="Agencia" label-for="issuePurchaseAgency"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <v-select v-model="formIssuePurchase.agency" :options="agenciesPerUsers" label="name"
                    :state="errors.length > 0 ? false : null" trim clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="promotor" rules="required">
                <b-form-group label="Promotor" label-for="issuePurchasePromoter"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="promoter" v-model="formIssuePurchase.promoter"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" trim
                    clearable disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Numero vaucher" rules="required">
                <b-form-group label="Numero vaucher" label-for="issuePurchasePromoter"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input v-model="formIssuePurchase.voucher_number" :state="errors.length > 0 ? false : null"
                    disabled :class="errors.length > 0 ? 'is-invalid' : null" trim clearable
                    @input="numberVoucher(formIssuePurchase.voucher_number)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Nombres y Apellidos" rules="required">
                <b-form-group label="Nombres y Apellidos" label-for="issuePurchaseFirstName"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="issuePurchaseFirstName" v-model="formIssuePurchase.firstName"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" trim
                    clearable />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- <b-col md="4">
              <validation-provider #default="{ errors }" name="Apellidos" rules="required">
                <b-form-group label="Apellidos" label-for="issuePurchaseLastName"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="issuePurchaseLastName" v-model="formIssuePurchase.lastName"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Correo" rules="required|email">
                <b-form-group label="Correo" label-for="email" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="email" type="email" v-model="formIssuePurchase.email"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Teléfono" rules="required">
                <b-form-group label="Teléfono" label-for="phone" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="phone" type="number" v-model="formIssuePurchase.phone"
                    :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>



          </b-row>
          <hr class="invoice-spacing">
          <h4 class="text-center">Viajeros</h4>
          <b-row class="p-1">
            <b-card-body class="invoice-padding form-item-section" style="border-radius: 8px;">
              <b-card v-for="(item, index) in formIssuePurchase.travellers" :key="index">
                <div class="d-none d-lg-flex" v-if="index == 0">
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12">
                      <b-row>
                        <!--travellers firstName-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Nombres viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Nombres" :label-for="'formIssuePurchaseFirstName' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input :id="'formIssuePurchaseFirstName' + index"
                                v-model="formIssuePurchase.travellers[index].firstName"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers lastName-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Apellidos viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Apellidos" :label-for="'formIssuePurchaseLastName' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="lastName" v-model="formIssuePurchase.travellers[index].lastName"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers passport-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Edad" rules="required">
                            <b-form-group label="Edad" label-for="age" :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="age" type="number" v-model="formIssuePurchase.travellers[index].age"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Fecha de nacimiento" rules="required">
                            <b-form-group label="Fecha de naciemiento">
                              <input type="date" v-model="formIssuePurchase.travellers[index].birthday"
                                class="form-control" style="margin-top: -8px;">
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Documento" rules="required">
                            <b-form-group label="Documento" label-for="age"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input type="number" v-model="formIssuePurchase.travellers[index].documentIdentity"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Pasaporte viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Pasaporte" :label-for="'formIssuePurchasePassport' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="passport" v-model="formIssuePurchase.travellers[index].passport"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Procedencia" rules="required">
                            <b-form-group label="Procedencia" label-for="procendencia"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].procedenty"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Correo viajero ' + (index + 1)"
                            rules="required|email">
                            <b-form-group label="Correo" label-for="email"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="email" type="email" v-model="formIssuePurchase.travellers[index].email"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="4">
                          <validation-provider #default="{ errors }" name="Numero vaucher" rules="required">
                            <b-form-group label="Numero vaucher" label-for="issuePurchasePromoter"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].voucher_number_child"
                                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                                trim clearable disabled />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="4">
                          <validation-provider #default="{ errors }" :name="'Teléfono viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Teléfono" label-for="phone"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="phone" type="number" v-model="formIssuePurchase.travellers[index].phone"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="4">
                          <validation-provider #default="{ errors }" :name="'Nacionalidad viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Nacionalidad" label-for="natonality"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="nationality" v-model="formIssuePurchase.travellers[index].nationality"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <!--travellers relationship-->

                      </b-row>
                      <b-row>
                        <!--travellers nationality-->

                        <!--travellers email-->

                        <!--travellers address-->
                        <b-col md="6">
                          <validation-provider #default="{ errors }" :name="'Dirección viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Dirección" label-for="address"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="address" v-model="formIssuePurchase.travellers[index].address"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers phone-->

                        <b-col md="6">
                          <validation-provider #default="{ errors }" name="Ciudad" rules="required">
                            <b-form-group label="Ciudad" label-for="procendencia"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].city" @input="duplicateInfo()"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="6">
                          <validation-provider #default="{ errors }"
                            :name="'Contacto en caso de emergencia ' + (index + 1)" rules="required">
                            <b-form-group label="Contacto de emergencia" :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].relationship"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="6">
                          <validation-provider #default="{ errors }"
                            :name="'Teléfono en caso de emergencia ' + (index + 1)" rules="required">
                            <b-form-group label="Teléfono en caso de emergencia "
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].phoneEmergency"
                                @input="duplicateInfo()" :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" type="number" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                    <feather-icon :id="`form-item-settings-icon-${index}`" size="16" icon="TrashIcon"
                      class="cursor-pointer text-danger" />

                    <!-- Setting Item Form -->
                    <b-popover :ref="`form-item-settings-popover-${index}`" :target="`form-item-settings-icon-${index}`"
                      triggers="click" placement="lefttop">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <p>¡Esta a punto de eliminar este pasajero!</p>
                          </b-col>
                          <b-col cols="12" class="d-flex justify-content-between mt-1">
                            <b-button variant="outline-danger"
                              @click="deleteTraveller(formIssuePurchase.travellers[index], index)">
                              Eliminar
                            </b-button>
                            <b-button variant="outline-secondary"
                              @click="() => { $refs[`form-item-settings-popover-${index}`][0].$emit('close') }">
                              Cancelar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-popover>
                  </div>
                </div>
                <div class="d-none d-lg-flex" v-else>
                  <b-row class="flex-grow-1 p-2">
                    <b-col cols="12">
                      <b-row>
                        <!--travellers firstName-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Nombres viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Nombres" :label-for="'formIssuePurchaseFirstName' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input :id="'formIssuePurchaseFirstName' + index"
                                v-model="formIssuePurchase.travellers[index].firstName"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers lastName-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Apellidos viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Apellidos" :label-for="'formIssuePurchaseLastName' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="lastName" v-model="formIssuePurchase.travellers[index].lastName"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers passport-->
                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Edad" rules="required">
                            <b-form-group label="Edad" label-for="age" :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="age" type="number" v-model="formIssuePurchase.travellers[index].age"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Fecha de nacimiento" rules="required">
                            <b-form-group label="Fecha de naciemiento">
                              <input type="date" v-model="formIssuePurchase.travellers[index].birthday"
                                class="form-control" style="margin-top: -8px;">
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Documento" rules="required">
                            <b-form-group label="Documento" label-for="age"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input type="number" v-model="formIssuePurchase.travellers[index].documentIdentity"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Pasaporte viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Pasaporte" :label-for="'formIssuePurchasePassport' + index"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="passport" v-model="formIssuePurchase.travellers[index].passport"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="Procedencia" rules="required">
                            <b-form-group label="Procedencia" label-for="procendencia"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].procedenty"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="3">
                          <validation-provider #default="{ errors }" :name="'Correo viajero ' + (index + 1)"
                            rules="required|email">
                            <b-form-group label="Correo" label-for="email"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="email" type="email" v-model="formIssuePurchase.travellers[index].email"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="4">
                          <validation-provider #default="{ errors }" name="Numero vaucher" rules="required">
                            <b-form-group label="Numero vaucher" label-for="issuePurchasePromoter"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].voucher_number_child"
                                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                                trim clearable disabled />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="4">
                          <validation-provider #default="{ errors }" :name="'Teléfono viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Teléfono" label-for="phone"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="phone" type="number" v-model="formIssuePurchase.travellers[index].phone"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="4">
                          <validation-provider #default="{ errors }" :name="'Nacionalidad viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Nacionalidad" label-for="natonality"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="nationality" v-model="formIssuePurchase.travellers[index].nationality"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>


                        <!--travellers relationship-->

                      </b-row>
                      <b-row>
                        <!--travellers nationality-->

                        <!--travellers email-->

                        <!--travellers address-->
                        <b-col md="6">
                          <validation-provider #default="{ errors }" :name="'Dirección viajero ' + (index + 1)"
                            rules="required">
                            <b-form-group label="Dirección" label-for="address"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input id="address" v-model="formIssuePurchase.travellers[index].address"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <!--travellers phone-->

                        <b-col md="6">
                          <validation-provider #default="{ errors }" name="Ciudad" rules="required">
                            <b-form-group label="Ciudad" label-for="procendencia"
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].city"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col md="6">
                          <validation-provider #default="{ errors }"
                            :name="'Contacto en caso de emergencia ' + (index + 1)" rules="required">
                            <b-form-group label="Contacto de emergencia" :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].relationship"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : null" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="6">
                          <validation-provider #default="{ errors }"
                            :name="'Teléfono en caso de emergencia ' + (index + 1)" rules="required">
                            <b-form-group label="Teléfono en caso de emergencia "
                              :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input v-model="formIssuePurchase.travellers[index].phoneEmergency"
                                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                                type="number" />
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                    <feather-icon :id="`form-item-settings-icon-${index}`" size="16" icon="TrashIcon"
                      class="cursor-pointer text-danger" />

                    <!-- Setting Item Form -->
                    <b-popover :ref="`form-item-settings-popover-${index}`" :target="`form-item-settings-icon-${index}`"
                      triggers="click" placement="lefttop">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <p>¡Esta a punto de eliminar este pasajero!</p>
                          </b-col>
                          <b-col cols="12" class="d-flex justify-content-between mt-1">
                            <b-button variant="outline-danger"
                              @click="deleteTraveller(formIssuePurchase.travellers[index], index)">
                              Eliminar
                            </b-button>
                            <b-button variant="outline-secondary"
                              @click="() => { $refs[`form-item-settings-popover-${index}`][0].$emit('close') }">
                              Cancelar
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-popover>
                  </div>
                </div>
              </b-card>
            </b-card-body>
          </b-row>
        </form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSpinbutton,
  VBModal,
  BModal,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, digits, integer } from '@validations'
import { get } from 'http';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils';
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormSpinbutton,
    BModal,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agenciesPerUsers: [],
      formData: {
        plan: '',
        modality: '',
        numberTravellers: 0,
        destiny: '',
        initial_date: '',
        final_date: '',
      },
      calculateCard: {
        show: false,
        age: null,
        days: null,
        travellers: null,
        plan: null,
        modality: null,
        trm: null,
        dolarValueBase: null,
        dolarValue: null,
        pesosValue: null,
        totalDolarValue: 0,
        totalPesosValue: null,
        discount: null,
        upgrade: false,
      },
      modalitiesOptions: [],
      planOptions: [],
      countriesOptions: [],
      numberTravellersData: {
        min: 0,
        max: 0,
      },
      name: '',
      nameState: null,
      submittedNames: [],
      formGenerateQuote: {
        agency: null,
        promoter: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      formIssuePurchase: {
        modal: false,
        agency: null,
        promoter: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        travellers: [],
        travellersDelete: [],
        age: null,
        birthday: null,
        documentIdentity: null,
        procedenty: null,
        city: null,
        voucher_number: null,
        phoneEmergency: null,
        voucher_number_child: null
      },
      id: this.$route.params && this.$route.params.id,
      voucher: this.$route.params && this.$route.params.voucher,
      cotization: this.$route.params && this.$route.params.cotization,
      store: this.$route.params && this.$route.params.store,
      planValue: null,
      voucher: null,
    }
  },
  watch: {
    'formData.modality': {
      handler(val) {
        if (val) {
          this.getModalityValues(val)
        } else {

        }
      }
    },
    'formData.plan.value': {
      handler(val) {
        if (val) {
          this.getCountriesOptions(val)
          if (this.id) {
            this.getCountriesOptions(val)
          } else {
            this.formData.destiny = ''
          }
        } else {
          this.formData.destiny = ''
        }
      }
    },
    'calculateCard.trm': {
      handler(val) {
        if (val) {
          this.calculateCard.pesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(val * this.calculateCard.dolarValue)
          this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format((this.calculateCard.totalDolarValue * this.calculateCard.travellers) * this.calculateCard.trm)
        } else {
          this.calculateCard.pesosValue = '0'
          this.calculateCard.totalPesosValue = '0'
        }
      }
    },
    'calculateCard.discount': {
      handler(val) {
        if (val) {
          if (this.calculateCard.upgrade) {
            // Resetear el valor anterior 
            this.calculateCard.dolarValue = parseFloat(this.calculateCard.dolarValue) - parseFloat(this.calculateCard.upgradePrice)
            this.calculateCard.totalDolarValue = this.calculateCard.dolarValue * this.calculateCard.travellers
            // Aumentar el valor upgrade 
            this.calculateCard.dolarValue = parseFloat(this.calculateCard.dolarValue) + parseFloat(this.calculateCard.upgradePrice)
            this.calculateCard.totalDolarValue = this.calculateCard.dolarValue * this.calculateCard.travellers
            // Calcular el descuento en dólares
            let discountDollar = (val / 100) * this.calculateCard.totalDolarValue
            // Restar el descuento en la variable
            this.calculateCard.totalDolarValue = this.calculateCard.totalDolarValue - discountDollar
            // Calcular el total en pesos
            this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
          } else {
            // Resetear el valor anterior
            this.calculateCard.totalDolarValue = this.calculateCard.dolarValue * this.calculateCard.travellers
            // Calcular el descuento en dólares
            let discountDollar = (val / 100) * this.calculateCard.totalDolarValue
            // Restar el descuento en la variable
            this.calculateCard.totalDolarValue = this.calculateCard.totalDolarValue - discountDollar
            // Calcular el total en pesos
            this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
          }
        } else {
          this.calculateCard.totalDolarValue = this.calculateCard.dolarValue * this.calculateCard.travellers
          this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.pesosValue * this.calculateCard.travellers)
        }
      }
    },
    'calculateCard.upgrade': {
      handler(val) {
        this.calculateCard.discount = 0
        if (val) {
          this.calculateCard.dolarValue = this.calculateCard.dolarValue + this.calculateCard.upgradePrice
          this.calculateCard.pesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.trm * this.calculateCard.dolarValue)

          if (this.calculateCard.discount && this.calculateCard.discount > 0) {
            this.calculateCard.totalDolarValue = parseFloat((this.calculateCard.upgradePrice * this.calculateCard.travellers) - ((this.calculateCard.upgradePrice * this.calculateCard.travellers) * (this.calculateCard.discount / 100))).toFixed(1)
          } else {
            this.calculateCard.totalDolarValue = parseFloat((this.calculateCard.totalDolarValue + this.calculateCard.upgradePrice) * this.calculateCard.travellers).toFixed(1)
          }

          this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
        } else {
          this.calculateCard.dolarValue = this.calculateCard.dolarValueBase
          this.calculateCard.pesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.trm * this.calculateCard.dolarValueBase)

          if (this.calculateCard.discount && this.calculateCard.discount > 0) {
            this.calculateCard.totalDolarValue = (this.calculateCard.dolarValueBase * this.calculateCard.travellers) - ((this.calculateCard.dolarValueBase * this.calculateCard.travellers) * (this.calculateCard.discount / 100))
          } else {
            this.calculateCard.totalDolarValue = this.calculateCard.dolarValueBase * this.calculateCard.travellers
          }

          this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
        }
      }
    },
    'formData.numberTravellers': {
      handler(val) {
        let travellersCount = this.formData.numberTravellers - this.formIssuePurchase.travellers.length
        if (travellersCount > 0) {
          if (val) {
            for (let index = 0; index < travellersCount; index++) {
              this.formIssuePurchase.travellers.push({
                id: null,
                firstName: '',
                lastName: '',
                passport: '',
                relationship: '',
                nationality: '',
                email: '',
                address: '',
                phone: '',
                age: '',
                birthday: '',
                documentIdentity: '',
                procedenty: '',
                city: '',
                phoneEmergency: '',
                voucher_number_child: ''
              })
            }
          }
        }
      }
    },
  },
  async created() {
    this.getModalitiesOptions()
    // this.getAgenciesOptions()
    this.getPromotersOptions()
    this.getAgenciesUserId()
    this.formAll()
    await this.initializeForm()
  },
  methods: {
    numberVoucher(numberVoucher) {
      let number = this.formIssuePurchase.voucher_number
      this.formIssuePurchase.travellers.forEach((children, index) => {
        this.formIssuePurchase.travellers[index].voucher_number_child = number
        number++
      });
    },
    duplicateInfo() {
      let firstChild = this.formIssuePurchase.travellers[0]
      this.formIssuePurchase.travellers.forEach((children, index) => {
        if (index > 0) {
          this.formIssuePurchase.travellers[index].phone = firstChild.phone
          this.formIssuePurchase.travellers[index].address = firstChild.address
          this.formIssuePurchase.travellers[index].relationship = firstChild.relationship

          this.formIssuePurchase.travellers[index].procedenty = firstChild.procedenty
          this.formIssuePurchase.travellers[index].email = firstChild.email
          this.formIssuePurchase.travellers[index].nationality = firstChild.nationality
          this.formIssuePurchase.travellers[index].city = firstChild.city
          this.formIssuePurchase.travellers[index].phoneEmergency = firstChild.phoneEmergency
        }
      });
    },
    formAll() {
      this.formIssuePurchase.firstName = this.userData.fullName
      this.formIssuePurchase.email = this.userData.email
      this.formIssuePurchase.phone = this.userData.phone
    },
    changePlan(data) {
      this.$http.get('/information/plan/' + data.value).then((response) => {
        this.planOptions = response.data.plans
        this.formGenerateQuote.agency = data.name
      })
    },
    formatPrice(value) {
      return parseFloat(value).toFixed(2)
    },
    calculateValues() {
      if (this.calculateCard.upgrade) {
        this.calculateCard.dolarValue = this.planValue.upgrade
      } else {
        this.calculateCard.dolarValue = this.planValue.value
      }
    },
    initializeForm() {
      if (this.id == undefined) {
        this.resetData()
      }
      if (this.id) {
        this.$http.get('/voucher/getVoucher/' + this.id).then((response) => {
          this.formData = {
            plan: { value: response.data.voucher.planValue.name_plan_id, name: response.data.voucher.plan },
            modality: response.data.voucher.modality.id,
            numberTravellers: response.data.voucher.numberTravellers,
            destiny: response.data.voucher.destination.id,
            initial_date: response.data.voucher.initialDate,
            final_date: response.data.voucher.finalDate,
          }
          this.getCountriesOptions(this.formData.plan.id)
          this.calculateCard = {
            show: true,
            age: response.data.voucher.age,
            days: response.data.voucher.days,
            travellers: response.data.voucher.numberTravellers,
            plan: response.data.voucher.plan,
            modality: response.data.voucher.modality.name,
            trm: response.data.voucher.trm,
            dolarValue: response.data.voucher.value,
            pesosValue: null,
            totalDolarValue: response.data.voucher.totalValue,
            totalPesosValue: null,
            discount: response.data.voucher.discount,
            upgrade: response.data.voucher.upgrade,
          }

          this.formGenerateQuote = {
            agency: response.data.voucher.agency.name,
            promoter: response.data.voucher.promoter.name,
            firstName: response.data.voucher.firstName,
            lastName: response.data.voucher.lastName,
            email: response.data.voucher.email,
            phone: response.data.voucher.phone,
          }
          let travellers = []
          if (response.data.voucher.voucher) {
            travellers = response.data.voucher.voucher.travellers
          }
          this.formIssuePurchase = {
            modal: false,
            agency: response.data.voucher.agency.name,
            promoter: response.data.voucher.promoter.name,
            voucher_number: response.data.voucher.voucher?.voucher_number ?? null,
            firstName: response.data.voucher.firstName,
            lastName: response.data.voucher.lastName,
            email: response.data.voucher.email,
            phone: response.data.voucher.phone,
            age: response.data.age,
            birthday: response.data.birthday,
            documentIdentity: response.data.documentIdentity,
            procedenty: response.data.procedenty,
            city: response.data.city,
            travellers: travellers,
            voucher_number_child: response.data.voucher_number_child,
            travellersDelete: [],
          }
          this.voucher = response.data.voucher.voucher
          this.planValue = response.data.voucher.planValue
          setTimeout(() => {
            this.calculateTrip()
          }, 100);
        })
      }
    },
    getModalitiesOptions() {
      this.$http.get('/information/modalities').then((response) => {
        this.modalitiesOptions = response.data.modalities
      })
    },
    getAgenciesUserId() {
      this.$http.get('/information/agenciesPerUsers/' + this.userData.id).then((response) => {
        this.agenciesPerUsers = response.data.agenciesPerId
      })
    },
    getCountriesOptions(plan) {
      this.$http.get('/information/countries/plan/' + plan).then((response) => {
        this.countriesOptions = response.data.countries
      })
    },
    getAgenciesOptions() {
      this.$http.get('cotization/get-agencies').then((response) => {
        // this.formIssuePurchase.agency = response.data.agency
        // this.formGenerateQuote.agency = response.data.agency
      })
    },
    getPromotersOptions() {
      this.$http.get('cotization/get-promoters').then((response) => {
        this.formIssuePurchase.promoter = response.data.promoter
        this.formGenerateQuote.promoter = response.data.promoter
      })
    },
    getModalityValues(id) {
      this.$http.get('cotization/get-modality-values/' + id).then((response) => {
        this.numberTravellersData.min = response.data.modality.people_min
        this.numberTravellersData.max = response.data.modality.people_max
        this.formData.numberTravellers = response.data.modality.people_min
      })
    },
    resetData() {
      this.id = null
      this.formData = {
        plan: '',
        modality: '',
        numberTravellers: 0,
        destiny: '',
        initial_date: '',
        final_date: '',
      }
      this.calculateCard = {
        show: false,
        age: null,
        days: null,
        travellers: null,
        plan: null,
        modality: null,
        trm: null,
        dolarValue: null,
        dolarValueBase: null,
        pesosValue: null,
        totalDolarValue: null,
        totalPesosValue: null,
        discount: null,
        upgrade: false,
        upgradePrice: false,
      }
      this.resetModalSave()
      this.$refs.formDataValidation.reset()
    },
    calculateTrip() {
      localize('es', es)
      this.$refs.formDataValidation.validate().then(success => {
        if (success) {
          this.$http.post('cotization/calculate', this.formData).then((response) => {
            // cambio nuevo 
            if (response.data.voucher_number) {
              this.formIssuePurchase.voucher_number = response.data.voucher_number
              this.numberVoucher(response.data.voucher_number)
            }
            this.calculateCard.age = response.data.age
            this.calculateCard.days = response.data.days
            this.calculateCard.plan = response.data.plan
            this.calculateCard.modality = response.data.modality
            this.calculateCard.travellers = response.data.travellers
            if (this.id == undefined) {
              this.calculateCard.trm = response.data.trm
              this.calculateCard.discount = '0'
            }
            this.calculateCard.dolarValue = response.data.dolarValue
            this.calculateCard.dolarValueBase = response.data.dolarValue
            this.calculateCard.upgradePrice = response.data.upgradePrice
            this.calculateCard.pesosValueUpgrade = response.data.pesosValueUpgrade
            this.calculateCard.show = true
            this.planValue = response.data.dolarValue

            setTimeout(() => {
              if (this.calculateCard.upgrade) {
                this.calculateCard.dolarValue = this.calculateCard.dolarValue + this.calculateCard.upgradePrice
                this.calculateCard.pesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.trm * this.calculateCard.dolarValue)

                if (this.calculateCard.discount && this.calculateCard.discount > 0) {
                  this.calculateCard.totalDolarValue = parseFloat((this.calculateCard.dolarValue * this.calculateCard.travellers) - ((this.calculateCard.dolarValue * this.calculateCard.travellers) * (this.calculateCard.discount / 100))).toFixed(1)
                } else {
                  this.calculateCard.totalDolarValue = parseFloat(this.calculateCard.dolarValue * this.calculateCard.travellers)
                }

                this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
              } else {
                this.calculateCard.dolarValue = this.calculateCard.dolarValueBase
                this.calculateCard.pesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.trm * this.calculateCard.dolarValueBase)

                if (this.calculateCard.discount && this.calculateCard.discount > 0) {
                  this.calculateCard.totalDolarValue = (this.calculateCard.dolarValueBase * this.calculateCard.travellers) - ((this.calculateCard.dolarValueBase * this.calculateCard.travellers) * (this.calculateCard.discount / 100))
                } else {
                  this.calculateCard.totalDolarValue = this.calculateCard.dolarValueBase * this.calculateCard.travellers
                }

                this.calculateCard.totalPesosValue = new Intl.NumberFormat('es-CO', { maximumSignificantDigits: 6 }).format(this.calculateCard.totalDolarValue * this.calculateCard.trm)
              }
            }, 100);
          })
        }
      })
    },
    saveQuote() {
      localize('es', es)
      this.$refs.formDataValidation.validate().then(success => {
        if (success) {
          let dataForm = {
            travelData: this.formData,
            calculateData: this.calculateCard,
          }
          this.$http.post('cotization/save', dataForm).then((response) => {
            console.log('ok')
          })
        }
      })
    },
    resetModalSave() {
      if (!this.id) {
        this.formGenerateQuote.firstName = ''
        this.formGenerateQuote.lastName = ''
        this.formGenerateQuote.email = ''
        this.formGenerateQuote.phone = ''
      }
      this.$refs.formGenerateQuoteValidation.reset()
    },
    handleOkGenerateQuote(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.id) {
        this.handleSubmitEditQuote()
      } else {
        this.handleSubmitGenerateQuote()
      }
    },
    handleSubmitGenerateQuote() {
      localize('es', es)
      this.$refs.formGenerateQuoteValidation.validate().then(success => {
        if (success) {
          let dataForm = {
            travelData: this.formData,
            calculateData: this.calculateCard,
            generateQuote: this.formGenerateQuote,
          }
          dataForm.travelData.plan = this.formData.plan.value
          this.$http.post('cotization/save-generate-quote', dataForm).then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Cotización creada con éxito`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Cotización número ${response.data.cotization.id}`,
              },
            })
            this.$router.push('/cotizations/cotization-history')
            this.$nextTick(() => {
              this.$refs['formGenerateQuote'].toggle('#toggle-btn')
            })
          })
        }
      })
    },
    handleSubmitEditQuote() {
      localize('es', es)
      this.$refs.formGenerateQuoteValidation.validate().then(success => {
        if (success) {
          let dataForm = {
            travelData: this.formData,
            calculateData: this.calculateCard,
            generateQuote: this.formGenerateQuote,
          }
          dataForm.travelData.plan = this.formData.plan.value
          this.$http.post('cotization/update-generate-quote/' + this.id, dataForm).then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Cotización editada con éxito`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Cotización número ${response.data.cotization.id}`,
              },
            })
            this.$router.push('/cotizations/cotization-history')
            this.$nextTick(() => {
              this.$refs['formGenerateQuote'].toggle('#toggle-btn')
            })
          })
        }
      })
    },
    openformIssuePurchaseModal() {
      this.formIssuePurchase.modal = true
    },
    resetIssuePurchaseModalSave() {
      //this.$refs.formIssuePurchaseValidation.reset()
    },
    handleOkGenerateIssuePurchase(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.id) {
        this.handleEditIssuePurchase()
      } else {
        this.handleSubmitIssuePurchase()
      }
    },
    handleSubmitIssuePurchase() {
      localize('es', es)
      this.$refs.formIssuePurchaseValidation.validate().then(success => {
        if (success) {
          let dataForm = {
            travelData: this.formData,
            calculateData: this.calculateCard,
            issuePurchase: this.formIssuePurchase,
          }
          this.$http.post('cotization/save-issue-purchase', dataForm).then((response) => {
            this.resetData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Váucher emitido con éxito`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Váucher número ${response.data.voucher.id}`,
              },
            })
            this.$router.push('/cotizations/vaucher-history')
            this.$nextTick(() => {
              this.$refs['formIssuePurchase'].toggle('#toggle-btn')
            })
          })
        }
      })
    },
    handleEditIssuePurchase() {
      localize('es', es)
      this.$refs.formIssuePurchaseValidation.validate().then(success => {
        if (success) {
          let travellersCount = this.formData.numberTravellers - this.formIssuePurchase.travellers.length
          if (travellersCount < 0) {
            this.$swal({
              title: 'Cancelado',
              text: 'debe eliminar ' + Math.abs(travellersCount) + (travellersCount == 1 ? ' viajeros' : ' viajero'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            let dataForm = {
              travelData: this.formData,
              calculateData: this.calculateCard,
              issuePurchase: this.formIssuePurchase,
              voucherData: this.voucher
            }
            this.$http.post('cotization/update-issue-purchase/' + this.id, dataForm).then((response) => {
              if (response.data.code == 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Váucher editado con éxito`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Váucher número ${response.data.voucher.id}`,
                  },
                })
                this.$router.push('/cotizations/vaucher-history')
              }
            })
          }
        }
      })
    },
    deleteTraveller(traveller, id) {
      if (traveller) {
        this.formIssuePurchase.travellersDelete.push(traveller);
      }
      const index = this.formIssuePurchase.travellers.findIndex(
        (concept) => concept.id === traveller.id
      );
      this.formIssuePurchase.travellers.splice(id, 1);

      if (this.formIssuePurchase.travellers.length == 1) {
        this.getModalityValues(1)
        this.formData.modality = 1
      }
      if (this.formIssuePurchase.travellers.length == 2) {
        this.getModalityValues(2)
        this.formData.modality = 2
      }
      if (this.formIssuePurchase.travellers.length == 3) {
        this.getModalityValues(3)
        this.formData.modality = 3
      }
      if (this.formIssuePurchase.travellers.length == 4) {
        this.getModalityValues(4)
        this.formData.modality = 4
      }



      // let travellersCount = this.formData.numberTravellers - this.formIssuePurchase.travellers.length
      // if (travellersCount > 0) {

      //   for (let index = 0; index < travellersCount; index++) {
      //     this.formIssuePurchase.travellers.push({
      //       id: null,
      //       firstName: '',
      //       lastName: '',
      //       passport: '',
      //       relationship: '',
      //       nationality: '',
      //       email: '',
      //       address: '',
      //       phone: '',
      //     })
      //   }

      // }

      // this.$refs[`form-item-settings-popover-${index}`][0].$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>